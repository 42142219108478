/* eslint-disable no-underscore-dangle */
import {
  Divider, message
} from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import FormCropProfile from '../../components/form-crop-profile';
import Spinner from '../../components/spinner';
import Title from '../../components/title';
import { cleanCropProfile, getCropProfileById, updateCropProfileById } from '../../redux/actions/crop-profile';
import { getAllEstablishments } from '../../redux/actions/establishment';
import { createParameter, deleteParemeterById, updateParemeterById } from '../../redux/actions/parameter';
import { getAllSensorTypes, getAllUnits } from '../../redux/actions/sensor';
import { parserParameterList } from '../../utils/parsers/crop-profile';
import { parserSelect } from '../../utils/parsers/establishment';

const EditCropProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { cropprofile, isLoading } = useSelector((state) => state.cropprofileReducer);
  const { establishments } = useSelector((state) => state.establishmentReducer);

  const { sensorTypes } = useSelector((state) => state.sensorReducer);

  const getData = () => {
    dispatch(getAllEstablishments());
    dispatch(getCropProfileById(id));
    dispatch(getAllSensorTypes());
    dispatch(getAllUnits());
  };

  useEffect(() => {
    getData();
    return () => {
      dispatch(cleanCropProfile());
    };
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleEditCropProfile = (values) => {
    dispatch(updateCropProfileById(id, values, handleGoBack));
  };

  const handleRemoveParameter = (id) => {
    const findId = cropprofile.parametros.find((st) => st._id === id);
    const tieneHumedad = findId.sensor.tipoSensor.nombre === 'Humedad';
    const tieneTemperatura = findId.sensor.tipoSensor.nombre === 'Temperatura';
    const virtualSensor = cropprofile.parametros.find((virtual) => virtual.sensor.tipoSensor.nombre === 'Virtual');
    const resultado = tieneHumedad || tieneTemperatura;
    if (resultado && virtualSensor) {
      message.error('No se puede eliminar sensor tipo Temperatura o Humedad si existe un sensor tipo Virtual');
    } else if (!resultado && virtualSensor) {
      dispatch(deleteParemeterById(id, getData));
    } else {
      dispatch(deleteParemeterById(id, getData));
    }
  };

  const createParameterSensor = (data) => {
    const temperaturaFound = cropprofile.parametros.filter(({ sensor }) => sensor.tipoSensor.nombre === 'Temperatura').length > 0;
    const humedadFound = cropprofile.parametros.filter(({ sensor }) => sensor.tipoSensor.nombre === 'Humedad').length > 0;
    const sensorTypeFound = sensorTypes.find((st) => st._id === data.typeSensores);
    const sensorVirtual = sensorTypeFound.nombre === 'Virtual';
    if (!sensorVirtual) {
      const newData = { ...data, perfilcultivo: id };
      const { ...rest } = newData;
      dispatch(createParameter(rest, getData));
    } else if (temperaturaFound && humedadFound) {
      const newData = { ...data, perfilcultivo: id };
      const { ...rest } = newData;
      dispatch(createParameter(rest, getData));
    } else {
      message.error('No puede editar perfil de cultivo');
    }
  };

  const editParameterSensor = (data) => {
    const newData = { ...data, perfilcultivo: id };
    const { key, ...rest } = newData;
    dispatch(updateParemeterById(key, rest, getData));
  };
  return (
    <div className="container-page">
      <div className="container-page__header">
        <Title text="Editar perfil de cultivo" />
      </div>
      <Divider />
      {isLoading
        ? <Spinner />
        : (
          <div>
            {cropprofile && (
              <FormCropProfile
                initialValues={cropprofile}
                data={parserParameterList(cropprofile.parametros)}
                onCreate={createParameterSensor}
                onEdit={editParameterSensor}
                onDelete={handleRemoveParameter}
                onSave={handleEditCropProfile}
                establishments={parserSelect(establishments)}
                selectDefault={cropprofile?.establecimientos}
                showId
                titleCopy="Id del sensor"
                dataCopy="sensorId"
              />
            )}
          </div>
        )}
    </div>
  );
};

export default EditCropProfile;
