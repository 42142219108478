import { Pagination, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SpinnerInto from '../../../components/spinner-inner-components';
import { getAllSerialsEstablishment } from '../../../redux/actions/serial';

const columns = [
    {
        title: 'Nombre',
        dataIndex: 'nombre',
        key: 'nombre',
    },
    {
        title: 'ID',
        dataIndex: 'serial',
        key: 'serial',
    },
    {
        title: 'Estado',
        dataIndex: 'vinculado',
        key: 'vinculado',
        render: (vinculado) => (vinculado ? 'Vinculado' : 'Disponible')
    },
];

const Serials = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const { isLoading } = useSelector((state) => state.serial);
    const dataSource = useSelector((state) => state.serial.serials?.data);
    const total = useSelector((state) => state.serial.serials?.total || 0);
    const [page, setPage] = useState(1);
    const pageSize = 10;

    useEffect(() => {
        if (id) {
            dispatch(getAllSerialsEstablishment(id));
        }
    }, [id, dispatch]);

    const handlePage = (newPage) => {
        setPage(newPage);
        // Si necesitas recargar los datos con la nueva página
        dispatch(getAllSerialsEstablishment(id, (newPage - 1) * pageSize, pageSize));
    };

    return (
        <div className="section-container">
            <p className="container-page__subtitle">Seriales</p>
            <div className="container-page__table">
                {isLoading ? (
                    <SpinnerInto />
                ) : (
                    <>
                        <Table
                            columns={columns}
                            dataSource={dataSource}
                            pagination={false}
                            locale={{ emptyText: 'No hay seriales conectados' }}
                        />
                        {dataSource && dataSource.length > 0 && (
                            <div className="container-page__pagination">
                                <Pagination
                                    current={page}
                                    onChange={handlePage}
                                    total={total}
                                    pageSize={pageSize}
                                    showSizeChanger={false}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default Serials;
