import { Button } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../icon/index';

const CardColor = ({
  value, color, onClick, disabled, icon, image
}) => {
  const colorClass = classNames('card-color', {
    red: color === 'red',
    green: color === 'green',
    yellow: color === 'yellow',
    grey: color === 'grey',
  });

  const renderContent = () => {
    if (icon) {
      return <Icon name={icon} size={90} />;
    }
    if (image) {
      return <img src={image} alt={value} className="card-color__image" />;
    }
    return <p className="card-color__value">{value}</p>;
  };

  return (
    <Button
      className={colorClass}
      onClick={onClick}
      disabled={disabled}
      role="button"
      tabIndex={0}
      aria-label={value}
    >
      {renderContent()}
    </Button>
  );
};

CardColor.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  color: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  image: PropTypes.string,
};

CardColor.defaultProps = {
  disabled: false,
  onClick: () => { },
  color: '',
  icon: '',
  image: '',
  value: '',
};

export default CardColor;
