/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
import {
  Col,
  Form,
  Row,
  Select, Switch
} from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/button';
import Icon from '../../../../components/icon';
import Input from '../../../../components/input';
import { getDevices } from '../../../../redux/actions/device';
import { parserSelectProfile } from '../../../../utils/parsers/crop-profile';
import { getValidationRules } from '../../../../utils/validations';
const { Item } = Form;

const FormModule = ({
  onSubmit, initialValues, cropprofiles, serials, establishment, type
}) => {
  const [form] = Form.useForm();
  const [circulacionAire, setCirculacionAire] = useState(initialValues.circulacionAire);
  const [humificadores, setHumificadores] = useState(initialValues.aireAcondicionado);
  const [generadores, setGeneradores] = useState(initialValues.generadorCO);
  const [serialFields, setSerialFields] = useState(() => {
    if (initialValues.seriales && initialValues.seriales.length > 0) {
      return initialValues.seriales.map((serial, index) => ({
        id: index + 1,
        value: serial.serial,
        showAuxiliares: true
      }));
    }
    return [{ id: 1, value: '', showAuxiliares: false }];
  });
  const dispatch = useDispatch();
  const devices = useSelector((state) => state.device.devices?.data || []);
  const [selectedDevices, setSelectedDevices] = useState(() => {
    const initialDevices = {};
    initialValues.seriales?.forEach((serial, index) => {
      serial.dispositivos?.forEach((device, deviceIndex) => {
        initialDevices[`dispositivo${deviceIndex + 1}_${index + 1}`] = device;
      });
    });
    return initialDevices;
  });
  const [selectedSerials, setSelectedSerials] = useState(() => {
    const initialSerials = {};
    initialValues.seriales?.forEach((serial, index) => {
      initialSerials[index + 1] = serial.serial;
    });
    return initialSerials;
  });

  useEffect(() => {
    dispatch(getDevices());
  }, [dispatch, serials, establishment]);

  const handleCirculacionAireChange = (checked) => {
    setCirculacionAire(checked);
  };

  const handleHumificadoresChange = (checked) => {
    setHumificadores(checked);
  };

  const handleGeneradoresChange = (checked) => {
    setGeneradores(checked);
  };

  const handleSerialChange = (value, serialId) => {
    setSelectedSerials(prev => ({
      ...prev,
      [serialId]: value
    }));

    const keysToClean = [1, 2, 3].map(num => `dispositivo${num}_${serialId}`);
    const newSelectedDevices = { ...selectedDevices };
    keysToClean.forEach(key => delete newSelectedDevices[key]);

    establishment?.modulos?.forEach(modulo => {
      modulo.seriales?.forEach(serialModulo => {
        if (serialModulo.serial === value) {
          serialModulo.dispositivos?.forEach(disp => {
            const key = `dispositivo${disp.posicion}_${serialId}`;
            newSelectedDevices[key] = 'Auxiliar no disponible';
            form.setFieldValue(key, 'Auxiliar no disponible');
          });
        }
      });
    });

    setSelectedDevices(newSelectedDevices);
    setSerialFields(prevFields =>
      prevFields.map(field =>
        field.id === serialId
          ? { ...field, value, showAuxiliares: true }
          : field
      )
    );
  };

  const handleAddSerial = () => {
    setSerialFields(prev => [...prev, {
      id: prev.length + 1,
      value: '',
      showAuxiliares: false
    }]);
  };
  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const serialFields = Object.keys(values)
        .filter(key => key.startsWith('serial_'))
        .map(key => {
          const serialId = key.split('_')[1];
          const dispositivos = [];

          // Solo agregar dispositivos que tengan un ID válido
          [1, 2, 3].forEach(num => {
            const deviceId = selectedDevices[`dispositivo${num}_${serialId}`];
            if (deviceId &&
              deviceId !== 'Auxiliar no disponible' &&
              typeof deviceId === 'string' &&
              deviceId.trim() !== '') {
              dispositivos.push({
                dispositivo: deviceId,
                posicion: num
              });
            }
          });

          // Solo retornar el serial si tiene dispositivos válidos
          if (values[key] && dispositivos.length > 0) {
            return {
              serial: values[key],
              dispositivos
            };
          }
          return null;
        })
        .filter(Boolean); // Eliminar los null

      const formattedValues = {
        numero: parseInt(values.numero, 10) || 0,
        establecimiento: establishment._id,
        perfilcultivo: values.perfilcultivo,
        seriales: serialFields,
        circulacionAire: values.circulacionAire || false,
        intraccionAire: parseInt(values.intraccionAire, 10) || 0,
        extraccionAire: parseInt(values.extraccionAire, 10) || 0,
        generadorCO: values.generadorCO || false,
        luminaria: parseInt(values.luminaria, 10) || 0,
        superficieCultivo: parseInt(values.superficieCultivo, 10) || 0,
        superficieTotal: parseInt(values.superficieTotal, 10) || 0,
        volumenTotal: parseInt(values.volumenTotal, 10) || 0,
        aireAcondicionado: values.aireAcondicionado || false,
        frigorias: parseInt(values.frigorias, 10) || 0
      };

      if (initialValues._id) {
        onSubmit({ key: initialValues._id, ...formattedValues });
      } else {
        onSubmit(formattedValues);
      }
    });
  };

  useEffect(() => {
    if (type === 'edit') {
      const formValues = {
        ...initialValues,
        perfilcultivo: initialValues?.perfilcultivo,
      };

      initialValues.seriales?.forEach((serial, index) => {
        const serialCompleto = serials?.find(s => s._id === serial.serial);
        formValues[`serial_${index + 1}`] = serialCompleto?._id;

        const dispositivosOrdenados = [...(serial.dispositivos || [])].sort((a, b) => a.posicion - b.posicion);

        dispositivosOrdenados.forEach(device => {
          const deviceName = devices.find(d => d._id === device.dispositivo)?.nombre || device.dispositivo;
          formValues[`dispositivo${device.posicion}_${index + 1}`] = deviceName;

          setSelectedDevices(prev => ({
            ...prev,
            [`dispositivo${device.posicion}_${index + 1}`]: device.dispositivo
          }));
        });
      });

      form.setFieldsValue(formValues);
    }
  }, [initialValues, form, serials, devices, type]);

  const handleDeviceSelect = (value, auxiliarId) => {
    setSelectedDevices(prev => ({
      ...prev,
      [auxiliarId]: value
    }));

    const selectedDevice = devices.find(d => d._id === value);
    if (selectedDevice) {
      form.setFieldValue(auxiliarId, `${selectedDevice.nombre}`);
    }
  };

  const getAvailableDevices = (currentAuxiliarId) => {
    const [, posicion, serialId] = currentAuxiliarId.match(/dispositivo(\d+)_(\d+)/);
    const selectedSerial = selectedSerials[serialId];
    const currentValue = selectedDevices[currentAuxiliarId];

    const serialObject = serials.find(serial => serial._id === selectedSerial);
    const posicionDisponible = serialObject?.posicionesDisponibles?.includes(parseInt(posicion));

    const selectedDeviceIds = Object.entries(selectedDevices)
      .filter(([key, value]) => {
        return key.endsWith(`_${serialId}`) &&
          key !== currentAuxiliarId &&
          value !== 'Auxiliar no disponible';
      })
      .map(([, deviceId]) => deviceId);

    const isOccupied = !posicionDisponible || selectedDeviceIds.includes(selectedDevices[currentAuxiliarId]);

    let options = devices.map(device => ({
      value: device._id,
      label: device.nombre,
      disabled: selectedDeviceIds.includes(device._id)
    }));

    if (type === 'edit' && currentValue) {
      const currentDevice = devices.find(d => d._id === currentValue);
      if (currentDevice) {
        const currentLabel = form.getFieldValue(currentAuxiliarId) || `Auxiliar ${posicion} - ${currentDevice.nombre}`;
        options = [
          {
            value: currentValue,
            label: currentLabel,
            isCurrentDevice: true
          },
          ...options.filter(opt => opt.value !== currentValue)
        ];
      }
    }

    return {
      options,
      isOccupied
    };
  };

  const getAvailableSerials = (currentSerialId) => {
    // Verificar que serials existe y es un array
    if (!Array.isArray(serials)) {
      return [];
    }

    // Obtener todos los seriales seleccionados actualmente
    const serialesSeleccionados = Object.values(selectedSerials).filter(Boolean);

    return serials
      .map(serial => ({
        value: serial._id,
        label: serial.nombre,
        disabled: (serial.posicionesDisponibles?.length === 0 ||
          serialesSeleccionados.includes(serial._id)) &&
          serial._id !== selectedSerials[currentSerialId]
      }))
      .filter(serial => {
        // No mostrar el serial si no tiene posiciones disponibles o ya está seleccionado
        const originalSerial = serials.find(s => s._id === serial.value);
        if (originalSerial?.posicionesDisponibles?.length === 0 ||
          serialesSeleccionados.includes(serial.value)) {
          return serial.value === selectedSerials[currentSerialId];
        }
        return true;
      });
  };

  const handleDeleteSerial = (serialId) => {
    setSerialFields(prevFields =>
      prevFields.filter(field => field.id !== serialId)
    );
    setSelectedSerials(prev => ({
      ...prev,
      [serialId]: undefined
    }));
  };

  return (
    <div>
      {initialValues
        ? (
          <Form form={form} layout="vertical" initialValues={initialValues} className="form-container">
            <Row gutter={16} className="row-container">
              <Col span={8}>
                <Item
                  className="form-container__item"
                  name="numero"
                  rules={getValidationRules('número')}
                  label="Número"
                >
                  <Input type="number" />
                </Item>
              </Col>
              <Col span={16}>
                <Item
                  className="form-container__item"
                  name="perfilcultivo"
                  label="Perfil de cultivo"
                >
                  <Select
                    disabled={!cropprofiles}
                    options={cropprofiles?.length !== 0 ? parserSelectProfile(cropprofiles) : []}
                  />
                </Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Item
                  className="form-container__item"
                  name="superficieTotal"
                  label="Superficie total (m2)"
                >
                  <Input type="number" />
                </Item>
              </Col>
              <Col span={8}>
                <Item
                  className="form-container__item"
                  name="superficieCultivo"
                  label="Superficie cultivo (m2)"
                >
                  <Input type="number" />
                </Item>
              </Col>
              <Col span={8}>
                <Item
                  className="form-container__item"
                  name="volumenTotal"
                  label="Volumen total (m3)"
                >
                  <Input type="number" />
                </Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Item
                  className="form-container__item"
                  name="intraccionAire"
                  label="Intracción aire (m3/h)"
                >
                  <Input type="number" />
                </Item>
              </Col>
              <Col span={8}>
                <Item
                  className="form-container__item"
                  name="extraccionAire"
                  label="Extracción aire (m3/h)"
                >
                  <Input type="number" />
                </Item>
              </Col>
              <Col span={8}>
                <Item
                  className="form-container__item"
                  name="luminaria"
                  label="Luces watts/m2"
                >
                  <Input type="number" />
                </Item>
              </Col>
            </Row>
            <Row gutter={16} className="row-container">
              <Col span={3}>
                <div className="switch-direction">
                  <Item
                    name="circulacionAire"
                  >
                    <Switch
                      checked={circulacionAire}
                      onChange={handleCirculacionAireChange}
                    />
                  </Item>
                  <p>Circulaciones de aire</p>
                </div>
                <div className="switch-direction">
                  <Item
                    name="aireAcondicionado"
                  >
                    <Switch
                      checked={humificadores}
                      onChange={handleHumificadoresChange}
                    />
                  </Item>
                  <p>Humificadores</p>
                </div>
                <div className="switch-direction">
                  <Item
                    name="generadorCO"
                  >
                    <Switch
                      checked={generadores}
                      onChange={handleGeneradoresChange}
                    />
                  </Item>
                  <p>Generadores de CO2</p>
                </div>
              </Col>
              <Col span={8} className="col-8-edit">
                {humificadores === true
                  && (
                    <Item
                      className="form-container__item"
                      name="frigorias"
                      label="A/C (frigorías)"
                    >
                      <Input type="number" />
                    </Item>
                  )}
              </Col>
            </Row>
            <Row gutter={16} className="row-container">

              {serialFields.map((serialField, index) => (
                <div
                  key={serialField.id}
                  style={{
                    borderRadius: '8px',
                    width: '100%'
                  }}
                >
                  {/* Campo del serial */}
                  <Col span={40}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <Item
                        className="form-container__item"
                        name={`serial_${serialField.id}`}
                        label="Serial"
                        style={{ width: '100%' }}
                      >
                        <Select
                          placeholder="Seleccione un serial"
                          onChange={(value) => handleSerialChange(value, serialField.id)}
                          disabled={!serials}
                          options={getAvailableSerials(serialField.id)}
                          style={{ width: '100%' }}
                        />
                      </Item>
                      {index > 0 && (
                        <Button
                          type="text"
                          icon={<Icon name="Delete" size={18} />}
                          onClick={() => handleDeleteSerial(serialField.id)}
                          style={{
                            background: 'transparent',
                            border: 'none',
                            boxShadow: 'none',
                            marginTop: 8

                          }}
                        />
                      )}
                    </div>
                  </Col>

                  {/* Auxiliares visibles siempre */}
                  {serialField.showAuxiliares && (
                    <div style={{ marginLeft: '24px' }}>
                      <Row>
                        <Col span={24}>
                          {[1, 2, 3].map(num => {
                            const currentAuxiliarId = `dispositivo${num}_${serialField.id}`;
                            const { options, isOccupied } = getAvailableDevices(currentAuxiliarId);
                            return (
                              <div key={num} style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '8px',
                                gap: '8px'
                              }}>
                                <span style={{ width: '50%' }}>Auxiliar {num}</span>
                                <Item
                                  className="form-container__item"
                                  name={currentAuxiliarId}
                                  style={{ marginBottom: 0, width: '50%' }}
                                >
                                  {(!isOccupied || (type === 'edit' && selectedDevices[currentAuxiliarId])) &&
                                    !(isOccupied && form.getFieldValue(currentAuxiliarId) === undefined) ? (
                                    <Select
                                      placeholder="Seleccione dispositivo"
                                      value={selectedDevices[currentAuxiliarId]}
                                      options={options}
                                      style={{ width: '100%' }}
                                      onChange={(value) => handleDeviceSelect(value, currentAuxiliarId)}
                                      onSearch={(value) => {
                                        if (type === 'edit' && selectedDevices[currentAuxiliarId]
                                          && form.getFieldValue(currentAuxiliarId)) {
                                          const [, posicion] = currentAuxiliarId.match(/dispositivo(\d+)/);
                                          form.setFieldValue(currentAuxiliarId, `Auxiliar ${posicion} - ${value}`);
                                        }
                                      }}
                                      showSearch
                                      mode={type === 'edit' && form.getFieldValue(currentAuxiliarId)
                                        ? 'combobox' : undefined}
                                      filterOption={(input, option) => {
                                        if (option.isCurrentDevice) return true;
                                        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
                                      }}
                                      listHeight={130}
                                      optionFilterProp="label"
                                    />
                                  ) : (
                                    <Input
                                      placeholder="Auxiliar no disponible"
                                      disabled
                                      style={{
                                        color: '#A9A9A9',
                                        backgroundColor: '#F5F5F5',
                                        borderColor: '#D3D3D3',
                                        width: '100%',
                                        height: '32px',
                                        padding: '4px 11px',
                                        borderRadius: '4px'
                                      }}
                                    />
                                  )}
                                </Item>
                              </div>
                            );
                          })}
                        </Col>
                      </Row>
                    </div>
                  )}

                </div>
              ))}
            </Row>

            {serialFields[serialFields.length - 1].showAuxiliares && (
              <Row gutter={16} className="row-container" justify="center">
                <Col span={12}>
                  <Button
                    type="default"
                    onClick={handleAddSerial}
                    style={{
                      width: '50%',
                      color: '#000000',
                      borderColor: '#000000',
                      backgroundColor: '#ffffff',
                      height: '32px',
                      lineHeight: '30px',
                      display: 'flex',
                      alignItems: 'center',
                      fontWeight: 500,
                      fontSize: '12px',
                      border: '1px solid #000000',
                      borderRadius: '2px',
                      '& .anticon': {
                        marginRight: '12px'
                      }
                    }}
                    icon={<Icon name="Add" size={18} />}
                    text="Agregar Serial"
                  />
                </Col>
              </Row>
            )}
            <Row justify="center">
              <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Item style={{ marginBottom: 0, width: '200px' }}>
                  <Button
                    type="primary"
                    onClick={handleSubmit}
                    text="Guardar cambios"
                  />
                </Item>
              </Col>
            </Row>
          </Form>
        )
        : <p>No se encontraron datos iniciales.</p>}
    </div >

  );
};

FormModule.propTypes = {
  initialValues: PropTypes.shape(),
  cropprofiles: PropTypes.arrayOf(PropTypes.shape()),
  serials: PropTypes.arrayOf(PropTypes.shape()),
  establishment: PropTypes.shape(),
  onSubmit: PropTypes.func,
  type: PropTypes.oneOf(['create', 'edit']).isRequired
};

FormModule.defaultProps = {
  onSubmit: () => { },
  serials: [],
  cropprofiles: [],
  initialValues: {},
  establishment: {},
  type: 'create'
};

export default FormModule;
