import { api } from '../../service/api';
import { DEVICE_PATH } from '../../service/path';
import { showErrorMessage, showSuccessMessage } from '../../utils/utils';

// Action Types
export const GET_DEVICES_REQUEST = 'GET_DEVICES_REQUEST';
export const GET_DEVICES_SUCCESS = 'GET_DEVICES_SUCCESS';
export const GET_DEVICES_FAILURE = 'GET_DEVICES_FAILURE';

export const CREATE_DEVICE = 'CREATE_DEVICE';
export const UPDATE_DEVICE = 'UPDATE_DEVICE';
export const DELETE_DEVICE = 'DELETE_DEVICE';

// Agregar estos tipos de acción
export const UPDATE_DEVICE_REQUEST = 'UPDATE_DEVICE_REQUEST';
export const UPDATE_DEVICE_SUCCESS = 'UPDATE_DEVICE_SUCCESS';
export const UPDATE_DEVICE_FAILURE = 'UPDATE_DEVICE_FAILURE';

// Action Creators
export const getDevices = () => (dispatch) => {
    dispatch({ type: GET_DEVICES_REQUEST });

    return api.get(DEVICE_PATH)
        .then(response => {
            dispatch({
                type: GET_DEVICES_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            dispatch({
                type: GET_DEVICES_FAILURE,
                payload: error.message
            });
        });
};

export const createDevice = (data, callback) => (dispatch) => {
    return api.post(DEVICE_PATH, data)
        .then(response => {
            dispatch({
                type: CREATE_DEVICE,
                payload: response.data
            });
            showSuccessMessage('El dispositivo fue creado correctamente', dispatch);
            if (callback) callback();
        })
        .catch(error => {
            showErrorMessage(error.response?.data?.message || 'Error al crear dispositivo', dispatch);
        });
};

export const updateDevice = (id, data, callback) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_DEVICE_REQUEST });
        const response = await api.patch(`${DEVICE_PATH}/${id}`, data);
        dispatch({
            type: UPDATE_DEVICE_SUCCESS,
            payload: response.data
        });
        showSuccessMessage('El dispositivo fue actualizado correctamente', dispatch);
        if (callback) callback();
    } catch (error) {
        dispatch({
            type: UPDATE_DEVICE_FAILURE,
            payload: error.message
        });
        showErrorMessage(error.response?.data?.message || 'Error al actualizar dispositivo', dispatch);
    }
};

export const deleteDevice = (id, callback) => (dispatch) => dispatch({
    type: DELETE_DEVICE,
    promise: api.delete(`${DEVICE_PATH}/${id}`),
    meta: {
        onSuccess: () => {
            showSuccessMessage('El dispositivo fue eliminado correctamente', dispatch);
            callback();
        },
        onFailure: (res) => {
            showErrorMessage(res.response.data.message, dispatch);
        }
    }
});
