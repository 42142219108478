import { DeleteOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, Input, Radio, TimePicker } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Icon from '../../icon';

const DevicesManager = ({
    title,
    onBack,
    deviceIcon
}) => {
    const [radioValue, setRadioValue] = useState('time');
    const [inputValue, setInputValue] = useState('');
    const [timeRanges, setTimeRanges] = useState([{ id: 1, startTime: null, endTime: null }]);

    return (
        <>
            <div className="modulos">
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    position: 'relative',
                    padding: '20px 0'
                }}>
                    <div className="card-info-home__button-nav">
                        <Button
                            icon={<LeftOutlined />}
                            color="black"
                            onClick={onBack}
                        />
                    </div>
                    <div style={{
                        display: 'flex', flexDirection: 'row', alignItems: 'center',
                        flexGrow: 1, justifyContent: 'center'
                    }}>
                        <Icon name={deviceIcon} style={{ marginRight: '8px' }} />
                        <h2
                            style={{
                                margin: 0,
                                fontSize: '18px',
                                textAlign: 'center',
                            }}
                        >
                            {title}
                        </h2>
                    </div>

                </div>
            </div>

            <div className="modulos">
                <div style={{ textAlign: 'center', margin: '20px 0' }}>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-around',

                }}>
                    <Button
                        disabled
                        style={{
                            backgroundColor: '#fff',
                            color: '#666666',
                            borderColor: '#666666',
                            width: '25%'
                        }}
                    >
                        ON
                    </Button>
                    <Button
                        disabled
                        style={{
                            backgroundColor: '#fff',
                            color: '#666666',
                            borderColor: '#666666',
                            width: '25%'
                        }}
                    >
                        OFF
                    </Button>
                    <Button
                        type="primary"
                        style={{
                            backgroundColor: 'rgba(0, 159, 203, 0.2)',
                            color: '#000000',
                            borderColor: '#00A0A0',
                            fontWeight: 'bold',
                            width: '25%'
                        }}
                    >
                        AUTO
                    </Button>
                </div>

                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '20px',
                    padding: '0 20px'
                }}>
                    <Radio.Group
                        value={radioValue}
                        onChange={(e) => setRadioValue(e.target.value)}
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <style>{`
                            .ant-radio-wrapper {
                                font-weight: bold !important;
                            }
                            .ant-radio-checked .ant-radio-inner {
                                border-color: #00A0A0 !important;
                                background-color: rgba(0, 159, 203, 0.2) !important;
                            }
                            .ant-radio-checked .ant-radio-inner::after {
                                 background-color: rgba(0, 159, 203, 0.1)
                            }
                            .ant-radio:hover .ant-radio-inner {
                                border-color: #00A0A0 !important;
                            }
                            .centered-timepicker .ant-picker-input > input {
                                text-align: center;
                                color: #666666;
                            }
                            .centered-timepicker .ant-picker-input > input[value] {
                                color: #000000 !important;
                                font-weight: 500;
                            }
                        `}</style>
                        <Radio value="time">Por tiempo</Radio>
                        <Radio value="threshold">Por Umbrales</Radio>
                    </Radio.Group>
                </div>

                {radioValue === 'time' && (
                    <div style={{
                        margin: '20px',
                    }}>
                        {timeRanges.map((range, index) => (
                            <div key={range.id}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginBottom: '15px',
                                    gap: '8px',
                                }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            gap: '8px',
                                            width: '100%',
                                        }}
                                    >
                                        <h2 style={{
                                            margin: 0,
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            color: '#333',
                                        }}>
                                            Inicio
                                        </h2>
                                        <TimePicker
                                            format="HH:mm"
                                            placeholder="HH-MM"
                                            value={range.startTime}
                                            onChange={(time) => {
                                                const newRanges = [...timeRanges];
                                                newRanges[index].startTime = time;
                                                setTimeRanges(newRanges);
                                            }}
                                            style={{
                                                width: '100%',
                                                height: '40px',
                                            }}
                                            inputReadOnly
                                            className="centered-timepicker"
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            gap: '8px',
                                            width: '100%',
                                        }}
                                    >
                                        <h2 style={{
                                            margin: 0,
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            color: '#333',
                                        }}>
                                            Fin
                                        </h2>
                                        <TimePicker
                                            format="HH:mm"
                                            placeholder="HH-MM"
                                            value={range.endTime}
                                            onChange={(time) => {
                                                const newRanges = [...timeRanges];
                                                newRanges[index].endTime = time;
                                                setTimeRanges(newRanges);
                                            }}
                                            style={{
                                                width: '100%',
                                                height: '40px',
                                            }}
                                            inputReadOnly
                                            className="centered-timepicker"
                                        />
                                    </div>
                                    {timeRanges.length > 1 && (
                                        <Button
                                            type="text"
                                            icon={<DeleteOutlined style={{ color: '#666666' }} />}
                                            onClick={() => {
                                                const newRanges = timeRanges.filter((_, i) => i !== index);
                                                setTimeRanges(newRanges);
                                            }}
                                            style={{
                                                marginTop: '25px',
                                                padding: 0,
                                                minWidth: '40px',
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        ))}

                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setTimeRanges([
                                    ...timeRanges,
                                    { id: Date.now(), startTime: null, endTime: null }
                                ]);
                            }}
                            style={{
                                color: '#009FCB',
                                textDecoration: 'none',
                                display: 'block',
                                textAlign: 'center',
                                marginTop: '10px',
                                fontSize: '14px'
                            }}
                        >
                            + Agregar nuevo rango de tiempo
                        </a>
                        <p style={{
                            marginTop: '18px',
                            fontSize: '14px',
                            color: '#000',
                            textAlign: 'center',
                            fontWeight: 'bold',
                        }}>
                            El forzador se encenderá a las 7am y se apagará a las 6pm
                        </p>
                    </div>
                )}

                {radioValue === 'threshold' && (
                    <div style={{
                        margin: '20px',
                        padding: '0 20px'
                    }}>
                        <h3 style={{
                            margin: 0,
                            fontSize: '16px',
                            fontWeight: 'normal'
                        }}>
                            Valor Optimo maximo de perfil
                        </h3>
                        <Input
                            placeholder="ºC"
                            type="text"
                            value={inputValue}
                            style={{
                                marginTop: '10px',
                                width: '15%'
                            }}
                            maxLength={2}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                }
                                const newValue = e.target.value + e.key;
                                if (parseInt(newValue) > 99) {
                                    e.preventDefault();
                                }
                            }}
                        />
                        {inputValue && (
                            <p style={{
                                marginTop: '18px',
                                fontSize: '14px',
                                color: '#000',
                                textAlign: 'center',
                                fontWeight: 'bold',
                            }}>
                                El a/c se encenderá cuando la temp supere los {parseFloat(inputValue) + 0.5}°c
                                y se apagará cuando baje a {parseFloat(inputValue) - 0.5}°c
                            </p>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

DevicesManager.propTypes = {
    title: PropTypes.string.isRequired,
    onBack: PropTypes.func.isRequired,
    deviceIcon: PropTypes.string.isRequired
};

export default DevicesManager;
