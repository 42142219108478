import {
    CREATE_SERIAL,
    DELETE_SERIAL,
    GET_SERIALS_FAILURE,
    GET_SERIALS_REQUEST,
    GET_SERIALS_SUCCESS,
    UPDATE_SERIAL
} from '../actions/serial';

const initialState = {
    serials: {
        data: [],
        total: 0
    },
    isLoading: false,
    error: null
};

const serialReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SERIALS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case GET_SERIALS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                serials: {
                    data: action.payload.data,
                    total: action.payload.total
                }
            };
        case GET_SERIALS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                serials: {
                    data: [],
                    total: 0
                }
            };
        case CREATE_SERIAL:
            if (action.error) {
                return {
                    ...state,
                    error: action.payload
                };
            }
            return {
                ...state,
                serials: {
                    ...state.serials,
                    data: [...state.serials.data, action.payload]
                }
            };
        case UPDATE_SERIAL:
            if (action.error) {
                return {
                    ...state,
                    error: action.payload
                };
            }
            return {
                ...state,
                serials: {
                    ...state.serials,
                    data: state.serials.data.map(serial =>
                        serial._id === action.payload._id ? action.payload : serial
                    )
                }
            };
        case DELETE_SERIAL:
            if (action.error) {
                return {
                    ...state,
                    error: action.payload
                };
            }
            return {
                ...state,
                serials: {
                    ...state.serials,
                    data: state.serials.data.filter(serial => serial._id !== action.payload)
                }
            };
        default:
            return state;
    }
};

export default serialReducer;
