/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import { combineReducers } from 'redux';
import { removeTokens } from '../../service/api';
import { LOGOUT } from '../actions/types';
import appReducer from './appReducer';
import binnacleReducer from './binnacles';
import campaignReducer from './campaignReducer';
import cropprofileReducer from './cropprofileReducer';
import deviceReducer from './device';
import establishmentReducer from './establishmentReducer';
import historicalReducer from './historicalReducer';
import moduleReducer from './moduleReducer';
import sensorReducer from './sensorReducer';
import serialReducer from './serialReducer';
import userReducer from './userReducer';

const MainReducer = combineReducers({
  appReducer,
  userReducer,
  sensorReducer,
  establishmentReducer,
  cropprofileReducer,
  historicalReducer,
  campaignReducer,
  binnacleReducer,
  moduleReducer,
  device: deviceReducer,
  serial: serialReducer
});
const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    removeTokens();
    state = undefined; // Resetea el estado a undefined
  }

  if (action.type === 'RESET') {
    state = undefined; // Resetea el estado a undefined
  }
  return MainReducer(state, action);
};
export default rootReducer;
