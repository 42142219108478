/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import {
    DeleteOutlined,
    EditOutlined
} from '@ant-design/icons';
import {
    Col,
    Form,
    Modal,
    Pagination,
    Row,
    Select,
    Space
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/button';
import Icon from '../../components/icon';
import Input from '../../components/input';
import Spinner from '../../components/spinner';
import Table from '../../components/table';
import { usePagination } from '../../hooks/usePagination';
import { createDevice, deleteDevice, getDevices, updateDevice } from '../../redux/actions/device';

const { Item } = Form;
const { Option } = Select;

const columns = [
    {
        title: 'Nombre',
        dataIndex: 'nombre',
        key: 'nombre'
    },
    {
        title: 'ID Dispositivo',
        dataIndex: 'idDispositivo',
        key: 'idDispositivo'
    },
    {
        title: 'Tipo de Dispositivo',
        dataIndex: 'tipoDispositivo',
        key: 'tipoDispositivo'
    }
];

const DevicesPage = () => {
    const dispatch = useDispatch();
    const rol = localStorage.getItem('rol');
    const [showModalNew, setShowModalNew] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [form] = Form.useForm();

    // Estado de Redux
    const state = useSelector((state) => state.device);
    const total = useMemo(() => state?.devices?.total || 0, [state]);
    const isLoading = useMemo(() => state?.isLoading || false, [state]);
    const dataSource = useMemo(() => state?.devices?.data || [], [state]);

    // Hook de paginación
    const {
        dataSource: paginatedDataSource,
        handlePage,
        page,
        total: paginatedTotal,
        isLoading: paginatedIsLoading,
    } = usePagination(getDevices, 'device', null, null);

    // Valores adaptados
    const adaptedDataSource = dataSource || paginatedDataSource;
    const adaptedTotal = total || paginatedTotal;
    const adaptedIsLoading = isLoading || paginatedIsLoading;

    useEffect(() => {
        dispatch(getDevices());
    }, [dispatch]);

    const openModal = () => {
        setShowModalNew(true);
    };

    const closeModal = () => {
        setShowModalNew(false);
        setShowModalEdit(false);
        setSelectedDevice(null);
        form.resetFields();
    };

    const handleDelete = (id) => {
        dispatch(deleteDevice(id, () => {
            dispatch(getDevices());
        }));
    };

    const handleEdit = (record) => {
        setSelectedDevice(record);
        form.setFieldsValue(record);
        setShowModalEdit(true);
    };

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            const deviceData = {
                nombre: values.nombre,
                idDispositivo: values.idDispositivo,
                tipoDispositivo: values.tipoDispositivo
            };

            if (selectedDevice) {
                dispatch(updateDevice(selectedDevice._id, deviceData, () => {
                    closeModal();
                    dispatch(getDevices());
                }));
            } else {
                dispatch(createDevice(deviceData, () => {
                    closeModal();
                    dispatch(getDevices());
                }));
            }
        }).catch((error) => {
            console.error('Error en la validación:', error);
        });
    };

    const actionsColumn = (record) => (
        <Space size="middle">
            <Button
                type="primary"
                icon={<EditOutlined />}
                color="transparent"
                text="Editar"
                onClick={() => handleEdit(record)}
            />
            <Button
                type="primary"
                icon={<DeleteOutlined />}
                color="transparent"
                text="Eliminar"
                onClick={() => handleDelete(record._id)}
            />
        </Space>
    );

    const renderForm = () => (
        <Form form={form} layout="vertical">
            <Row gutter={16}>
                <Col span={12}>
                    <Item
                        name="nombre"
                        label="Nombre"
                        rules={[{ required: true, message: 'Por favor ingrese el nombre' }]}
                    >
                        <Input />
                    </Item>
                </Col>
                <Col span={12}>
                    <Item
                        name="idDispositivo"
                        label="ID Dispositivo"
                        rules={[{ required: true, message: 'Por favor ingrese el ID' }]}
                    >
                        <Input />
                    </Item>
                </Col>
            </Row>
            <Item
                name="tipoDispositivo"
                label="Tipo de Dispositivo"
                rules={[{ required: true, message: 'Por favor seleccione el tipo' }]}
            >
                <Select placeholder="Seleccione un tipo">
                    <Option value="lavarropas">Lavarropas</Option>
                    <Option value="sensor">Sensor</Option>
                    <Option value="control">Control</Option>
                </Select>
            </Item>
            <Item style={{ textAlign: 'center', marginTop: '20px' }}>
                <Button type="primary" onClick={handleSubmit} text="Guardar" />
            </Item>
        </Form>
    );

    return (
        <div className="container-page__box">
            <div
                className="container-page__header"
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '24px 28px'
                }}
            >
                <div className="container-page__title">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Icon
                            name="Home"
                            size={64}
                        />
                        <div style={{
                            marginLeft: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: '64px'
                        }}>
                            <h1 style={{
                                margin: 0,
                                fontSize: '24px',
                                color: '#306A59'
                            }}>
                                Dispositivos
                            </h1>
                            <div
                                style={{
                                    width: '50%',
                                    height: '8px',
                                    backgroundColor: '#306A59'
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="container-page__actions">
                    {rol === 'superadmin' && (
                        <Button
                            text={(
                                <>
                                    <span style={{ textDecoration: 'underline' }}>Agregar dispositivo</span>
                                    {' +'}
                                </>
                            )}
                            color="transparent"
                            onClick={openModal}
                        />
                    )}
                </div>
            </div>
            <div className="container-page__table" style={{ padding: '0 28px' }}>
                {adaptedIsLoading ? (
                    <Spinner />
                ) : (
                    <>
                        <Table
                            columns={columns}
                            dataSource={adaptedDataSource}
                            actionsColumn={actionsColumn}
                            actions={rol === 'superadmin'}
                        />
                        <div className="container-page__pagination">
                            <Pagination
                                current={page}
                                onChange={handlePage}
                                total={adaptedTotal}
                                pageSize={10}
                            />
                        </div>
                    </>
                )}
            </div>
            <Modal
                title="Crear nuevo Dispositivo"
                open={showModalNew}
                onOk={closeModal}
                onCancel={closeModal}
                footer={null}
            >
                {renderForm()}
            </Modal>
            <Modal
                title="Editar Dispositivo"
                open={showModalEdit}
                onOk={closeModal}
                onCancel={closeModal}
                footer={null}
            >
                {renderForm()}
            </Modal>
        </div>
    );
};

export default DevicesPage;
